@import 'fonts/fonts';

@import 'base/girds', // xs:0, sm:576px (small), md:768px (medium), lg:992px (large), xl:1200px (big)
'base/colors', // color(<key>) | tint(<key>, <percent>) [rozjaśnienie] | shade(<key>, <percent>)
'base/gradients', // gradient(<key>)
'base/shadows';
// shadow(<key>)

@import 'modules/finder'; // Wyszukiwarka (without the content!)

main {
  .main-content {

    .modal-all-list {
      position: fixed;
      z-index: 2000;
      padding-top: 10vh;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgba(0, 0, 0, 0.8);

      /* The Close Button */
      .close {
        color: white;
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 35px;
        font-weight: bold;

        &:hover,
        &:focus {
          color: #999;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .modal-content {
        position: relative;
        background-color: rgba(0, 0, 0, 0.3);
        margin: auto;
        padding: 10px 0;
        width: 100%;
        max-width: 800px;

        .slide-image {
          /* Hide the slides by default */
          display: none;
          width: 100%;
          text-align: center;

          .slide-number {
            color: white;
            background-color: rgba(0, 0, 0, 0.55);
            text-align: center;
            font-size: 16px;
            line-height: 25px;
            width: 50px;
            padding: 0;
            position: absolute;
            top: -25px;
            left: -50px;
            border-radius: 5px;
          }

          img {
            width: auto;
            height: auto;
            margin: 0 auto;
            max-width: 100%;
            max-height: calc(90vh - 190px);
            transition: 1s;

            &:hover {
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }
          }
        }

        /* Next & previous buttons */
        .prev,
        .next {
          cursor: pointer;
          position: absolute;
          top: 50%;
          width: auto;
          padding: 16px;
          margin-top: -50px;
          color: white;
          background-color: rgba(0, 0, 0, 0.15);
          font-weight: bold;
          font-size: 20px;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          /*user-select: none;*/
          -webkit-user-select: none;

          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }

        /* Position the "next button" to the right */
        .next {
          right: 0;
          border-radius: 3px 0 0 3px;
        }

        .slide-name-box {
          text-align: center;
          padding: 0;
          color: white;
          font-size: 16px;
          line-height: 40px;

          .slide-name {
            /* Hide the slides by default */
            display: none;
            margin: 0 0 10px;
            padding: 2px;
          }
        }

        .slide-thumb-box {
          font-size: 0;
          line-height: 0;

          .slide-thumb {
            opacity: 0.6;
            padding: 1px;

            &.active,
            &:hover {
              opacity: 1;
            }
          }
        }

      }
    }

    .header {

      .left-header {
        text-align: center;

        .title {
          font-size: 35px;
          color: color(gray-dark);
        }

        .subtitle {
          font: font(title-5);
        }

        .sponsors {

          .row {
            align-items: center;
            display: flex;
          }

          .sponsor {
            -webkit-filter: contrast(100%) grayscale(100%);
            filter: contrast(100%) grayscale(100%);
            margin: 0 20px;
            height: 100%;

            &.short {
              width: 120px;
              max-width: 20%;
            }

            &.long {
              width: 240px;
              max-width: 30%;
            }

            &.contrast {
              -webkit-filter: contrast(10%) grayscale(100%);
              filter: contrast(10%) grayscale(100%);
            }
          }

          .sponsor:hover {
            filter: none;
            -webkit-filter: contrast(100%) grayscale(0%);
          }
        }

        @include respond-to(large) {
          text-align: left;
          width: 90%;
          font: font(title-4);

          .h-1 {
            text-align: left;
          }
        }
      }

      .right-header {
        text-align: center;
        padding-top: 60px;

        .thumb-resource{
          border-radius: 18px 18px 18px 0 !important;
        }

        @include respond-to(large) {
          text-align: right;
          padding-top: 0;
        }
      }
    }



      .finder-block {

        .finder-list {

          .block-item {
            padding: 20px 10px;

            .card {
              padding: 10px 10px 0 10px;
              border: 0;
              height: 100%;
              border-radius: 10px;
              background-color: white;
              text-align: center;
              font-size: 1.0em;
              line-height: 1.2em;
              transition: all .3s;
              cursor: pointer;

              &:hover {
                -webkit-animation: exhibitor-slide-top 0.4s ease-in-out both;
                animation: exhibitor-slide-top 0.4s ease-in-out both;
                -webkit-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.09);
                -moz-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.09);
                box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.09);

              }

              &:active {
                -moz-transform: translate(0px, -11px);
                -ms-transform: translate(0px, -11px);
                -o-transform: translate(0px, -11px);
                -webkit-transform: translate(0px, -11px);
                transform: translate(0px, -11px);
                -webkit-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.09);
                -moz-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.09);
                box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.09);
              }

              .card-body {
                padding: 0;

                //.dropdown-menu {
                //  left: -26ch !important;
                //  min-width: 300px;
                //  border-radius: 16px 0 16px 16px;
                //  z-index: 30;
                //}

                .admin-actions {
                  position: relative;
                  z-index: 10;
                  text-align: right;
                  border-radius: 5px;

                  .admin-input {
                    display: inline-block;
                    position: relative;
                    width: 30%;

                    .form-control {
                      padding-top: 0;
                      padding-bottom: 0;
                      height: 24px;
                    }
                  }

                  .order-block {
                    white-space: nowrap;
                    padding: 0;
                    margin: 0 4px 0 auto;
                    display: inline-block;
                    line-height: 0;
                    font-size: 0;
                    width: 80px;
                    border: none;

                    input.form-control {
                      font-size: 2vh;
                      padding: 2.5px 0;
                      width: calc(100% - 30px);
                      color: color(blue);
                      font-weight: bold;
                      border-color: color(blue);
                      border-width: 2px;
                      text-align: center;
                      height: 100%;
                    }

                    .input-group-append {
                      font-size: 12px;
                      display: inline-block;
                      width: 30px;
                      text-align: center;
                      color: color(blue);
                      border-color: color(blue);
                      border-width: 1px;
                    }
                  }

                  .admin-info {
                    float: left;
                    border-width: 2px;
                    font-size: 14px;
                    border-radius: 6px;
                  }

                  button {
                    margin: 0;
                    text-align: center;
                    font-size: 14px;
                    line-height: 1.15;
                    border: 2px solid #009ee5;
                    padding: 2px 4px;
                    background-color: white;

                    &:hover {
                      background-color: color(yellow);
                      border-color: color(gold);
                    }
                  }
                }

                .item-block-title {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 1.3;
                  color: color(4);
                  text-align: center;
                  white-space: normal;
                  margin: 0 auto;
                  width: 90%;
                  overflow: hidden;
                }

                .item-block-image {
                  display: block;
                  position: relative;
                  height: 260px;
                  //border: 1px solid color(img-border);
                  //margin: -10px -10px 15px -10px;
                  padding: 0;
                  background-color: white;
                  border-radius: 50px;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: 62% auto;

                  &.link-profile {
                    //border: 3px solid color(blue);//#60b4ea;
                  }
                }

                .item-block-city {
                  padding: 0;

                  .city-name {
                    line-height: 16px;
                    text-align: center;
                    padding: 25px 5px 5px 15px;
                    border-radius: 0;
                    color: shade(red, 10);
                    font-weight: 550;
                    font-size: 14px;
                    text-transform: uppercase;
                  }
                }

                .item-block-description {
                  text-align: justify;
                  font-size: 0.85em;
                  color: color(base);
                  height: 50px;
                  overflow: no-display;
                }
              }

              .card-footer {
                margin: 5px -10px 0 -10px;
                background-color: color(none);
                border: none;
                padding: 0;

                .btn-success {
                  margin-bottom: 10px;
                  min-width: 60%;
                }

                .row {
                  margin: 0;
                  padding: 0;

                  > .btn {
                    border-radius: 0;
                    padding: 5px 0;
                    font-size: 0.95rem;

                    &:first-child {
                      border-radius: 0 0 0 15px;
                    }

                    &:last-child {
                      border-radius: 0 0 15px 0;
                    }

                    &.btn-light {
                      cursor: progress;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
}


.custom-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 27px;
  border-radius: 27px;
  background-color: #f3f4f4;
  cursor: pointer;
  transition: all .3s;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, .3);

  input {
    display: none;
  }

  input:checked + div {
    left: calc(60px - 22px);
    box-shadow: 0px 0px 0px white;
  }

  div {
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 17px;
    background-color: white;
    top: 5px;
    left: 5px;
    box-shadow: 0px 0px 1px rgb(150, 150, 150);
    transition: all .3s;
  }

  &.style-1 {
    background-color: #f44e66;
    box-shadow: none;
  }

}








  // Modal
#modalAdd,
#modalAddMedia {

  .modal-dialog {
    width: 90%;
    max-width: 1200px;

  }
}